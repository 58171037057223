import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inputs: {},
};

const FormInputs = createSlice({
  name: "formInputs",
  initialState,
  reducers: {
    form: (state, action) => {
      state.inputs = action.payload;
    },
    setFirst: (state, action) => {
      state.inputs.first = action.payload;
    },
  },
});

export const { form, setFirst } = FormInputs.actions;

export default FormInputs.reducer;
