import React from "react";
import style from "./index.module.scss";
import {
  FooterLinks,
  HeaderLinks,
  FooterLinksEng,
  HeaderLinksEng,
} from "../../utilities/HeaderLinks";
import { Link } from "react-router-dom";
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import logo from "./../../assets/logo.png";
import { useSelector } from "react-redux";
function Footer() {
  const Lang = useSelector((state) => state.Lang.lang);
  return (
    <footer className={style.Footer}>
      <div className={style.footerUpper}>
        <div className={style.companyInfo}>
          <div className={style.logo_area}>
            <Link to="">
              {" "}
              <img src={logo} alt="Company Logo" className={style.logo_img} />
            </Link>
          </div>
          <div className={style.contact}>
            <span className={style.tel}>tel:6934394416</span>
            <span className={style.email}>
              e-mail:greenandbluedev@gmail.com
            </span>
          </div>
        </div>
        <div className={style.theCompnay}>
          {Lang == "En"
            ? HeaderLinksEng.map((link) => (
                <Link
                  to={link.link}
                  key={link.id}
                  className={style.navbar_link}
                >
                  {link.title}
                </Link>
              ))
            : HeaderLinks.map((link) => (
                <Link
                  to={link.link}
                  key={link.id}
                  className={style.navbar_link}
                >
                  {link.title}
                </Link>
              ))}
        </div>
        <div className={style.terms}>
          <ul className={style.FooterList}>
            {Lang == "En"
              ? FooterLinksEng.map((link) => (
                  <li className={style.FooterListItem} key={link.id}>
                    <Link to={link.link} className={style.FooterItem}>
                      {link.title}
                    </Link>
                  </li>
                ))
              : FooterLinks.map((link) => (
                  <li className={style.FooterListItem} key={link.id}>
                    <Link to={link.link} className={style.FooterItem}>
                      {link.title}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
        <div className={style.socials}>
          <div>
            <span>
              <BsFacebook />
            </span>
            <span>
              <BsTwitter />
            </span>
            <span>
              <BsLinkedin />
            </span>
          </div>
        </div>

        <div className={style.espa}>
          <img src={require("./../../assets/espa.png")} alt="espa flag" />
        </div>
      </div>

      <div className={style.footerlower}>
        <div className={style.sopyrights}>
          &#169; NafpaktosTourismHub.gr All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
