import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "El",
};

const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    getLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { getLang } = LanguageSlice.actions;

export default LanguageSlice.reducer;
