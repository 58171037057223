export const alternativeEn = [
  {
    id: 1,
    title: "Agritourism",
    rooms: true,
    activites: [
      {
        id: 12,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Ride by the sea, on lakes or in the countryside with the help of
            chaperones. Equestrian centers are located inside the guesthouses or
            at a distance of up to 10 km
          </p>
        ),
      },
      {
        di: 13,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Walk and explore mapped trails, accompanied by the owners, expert
            guides or on your own, starting from your place of residence or up
            to 10 km away
          </p>
        ),
      },
      {
        id: 14,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Stay near rivers where you will have the opportunity to enjoy
            canoeing, kayaking or rafting with experienced guides within 15 km
            of the guesthouse
          </p>
        ),
      },
      {
        id: 15,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Go cycling and explore the Greek countryside and its beauties.
            Bicycles are available at the guesthouse or at a distance of up to 5
            km
          </p>
        ),
      },
      {
        id: 17,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Observe unique species of migratory birds in protected wetlands up
            to 15 km from your accommodation
          </p>
        ),
      },
      {
        id: 18,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Stay on a farm, discover agricultural activities and taste local
            products. Get close to nature and experience an unforgettable
            vacation
          </p>
        ),
      },
      {
        id: 19,
        title: (
          <p style={{ fontSize: "18px", fontFamily: "Times New Roma" }}>
            Live the exhilarating experience of rock climbing. The owners will
            give you all the necessary information but not the equipment
          </p>
        ),
      },
    ],
    item: [
      {
        id: 2,
        title: "EVINOS ORGANIC GARDEN-FARM",
        summary: (
          <div>
            <p>
              Στα μέσα της διαδρομής Μεσολογίου-Ναυπάκτου, το βιολογικό
              αγρόκτημα-κήπος “Evinos Garden” με ελαιώνες και οπωροφόρα δέντρα,
              βρίσκεται σε ένα ήρεμο φυσικό περιβάλλον ιδανικό για χαλαρωτικές
              διακοπές μακριά από το άγχος της καθημερινότητας. Θερμό
              καλωσόρισμα και ζεστή οικογενειακή ατμόσφαιρα.
            </p>
            <p>
              Το αγρόκτημα 8 στρεμμάτων διαθέτει 4 παραδοσιακές πετρόχτιστες
              κατοικίες τοπικής αρχιτεκτονικής, στούντιο και διαμερίσματα γύρω
              από μια πισίνα. Η θέα που προσφέρουν στους γύρω λόφους και την
              μοναδική κοίτη του ποταμού Ευήνου είναι εκπληκτική.
            </p>
            <p>
              Λειτουργικά και πλήρως εξοπλισμένα, μπορούν να φιλοξενήσουν από 2
              έως 4 άτομα άνετα.
            </p>
            <p>
              Οι επισκέπτες μπορούν να ξεκινήσουν τη μέρα τους με ένα γευστικό
              πρωινό που αποτελείται από βιολογικά (όσο είναι δυνατό) προϊόντα
              που παράγονται στο κτήμα ή από τοπικούς παραγωγούς.
            </p>
          </div>
        ),
        rooms: [
          {
            id: 3,
            title: "Miguel De Cervantes House 39 τ.μ",
            price_breakdown: {
              all_inclusive_price: 45,
            },
            adults: 4,
            children: true,
            url: "https://www.booking.com/hotel/gr/evinos-garden.el.html?aid=2390323&no_rooms=1&group_adults=2&label=NafpaktosTourismHub",
            address: "Trikorfo, Etoloakarnania, Central Greece",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/ec33f6abb1ebb8f3e8f4b102740b2fc9.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/17ae5ad128e99111a7084e51c743dfda.jpg",
              },
            ],
            services: (
              <ul>
                <li>Fireplace</li>
                <li>Air conditioning</li>
                <li>Fully equipped kitchen</li>
                <li>Bathroom with shower</li>
                <li>Phone</li>
                <li>Satellite TV</li>
                <li>Veranda</li>
                <li>Garden</li>
                <li>Mountain View</li>
                <li>Ground floor</li>
                <li>Safe</li>
                <li>Hair dryer</li>
                <li>1 Double Bed</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Coffee maker</li>
                <li>Bath products</li>
                <li>Garden View</li>
                <li>2 sofa beds for 1 person</li>
              </ul>
            ),
          },
          {
            id: 4,
            title: "Charilaos Trikoupis House 41 τ.μ",
            adults: 4,
            url: "https://www.booking.com/hotel/gr/evinos-garden.el.html?aid=2390323&no_rooms=1&group_adults=2&label=NafpaktosTourismHub",
            price_breakdown: {
              all_inclusive_price: 47,
            },
            address: "Trikorfo, Etoloakarnania, Central Greece",
            children: true,
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/2847c0ef5fdf67581175507209574706.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/15a6228686842a78a92151128ddedf90.jpg",
              },
            ],
            services: (
              <ul>
                <li>Fireplace</li>
                <li>Air conditioning</li>
                <li>Fully equipped kitchen</li>
                <li>Bathroom with shower</li>
                <li>Phone</li>
                <li>Satellite TV</li>
                <li>Veranda</li>
                <li>Garden</li>
                <li>Mountain View</li>
                <li>Ground floor</li>
                <li>Safe</li>
                <li>Hair dryer</li>
                <li>1 Double Bed</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Coffee maker</li>
                <li>Bath products</li>
                <li>Garden View</li>
                <li>2 sofa beds for 1 person</li>
              </ul>
            ),
          },
          {
            id: 5,
            title: "Deianira Studio 31 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 52,
            },
            address: "Trikorfo, Etoloakarnania, Central Greece",
            url: "https://www.booking.com/hotel/gr/evinos-garden.el.html?aid=2390323&no_rooms=1&group_adults=2&label=NafpaktosTourismHub",

            children: true,
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/ac12746fa6284381f660c69073f8bb8a.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/17ae5ad128e99111a7084e51c743dfda.jpg",
              },
            ],
            services: (
              <ul>
                <li>Fireplace</li>
                <li>Air conditioning</li>
                <li>Fully equipped kitchen</li>
                <li>Bathroom with shower</li>
                <li>Phone</li>
                <li>Satellite TV</li>
                <li>Veranda</li>
                <li>Garden</li>
                <li>Mountain View</li>
                <li>Ground floor</li>
                <li>Safe</li>
                <li>Hair dryer</li>
                <li>1 Double Bed</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Coffee maker</li>
                <li>Bath products</li>
                <li>Garden View</li>
                <li>2 sofa beds for 1 person</li>
              </ul>
            ),
          },
          {
            id: 6,
            title: "Lord Byron House 41 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 55,
            },
            address: "Trikorfo, Etoloakarnania, Central Greece",
            url: "https://www.booking.com/hotel/gr/evinos-garden.el.html?aid=2390323&no_rooms=1&group_adults=2&label=NafpaktosTourismHub",
            children: true,
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/318b806b2121e267e99ded9e70a63b41.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/92fd476f6fb50a54dae1ba3425ca1974.jpg",
              },
            ],
            services: (
              <ul>
                <li>Fireplace</li>
                <li>Air conditioning</li>
                <li>Fully equipped kitchen</li>
                <li>Bathroom with shower</li>
                <li>Phone</li>
                <li>Satellite TV</li>
                <li>Veranda</li>
                <li>Garden</li>
                <li>Mountain View</li>
                <li>Ground floor</li>
                <li>Safe</li>
                <li>Hair dryer</li>
                <li>1 Double Bed</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Coffee maker</li>
                <li>Bath products</li>
                <li>Garden View</li>
                <li>2 sofa beds for 1 person</li>
              </ul>
            ),
          },
          {
            id: 7,
            title: "Giannis Vlachogiannis House 47 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 65,
            },
            address: "Trikorfo, Etoloakarnania, Central Greece",
            url: "https://www.booking.com/hotel/gr/evinos-garden.el.html?aid=2390323&no_rooms=1&group_adults=2&label=NafpaktosTourismHub",
            children: true,
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/b8b17c99ea65dea6a3955ddd870757be.jpg",
              },
              {
                id: 5,
                src: "https://cdn.webhotelier.net/photos/w=1920/evinosgard-gianni/L215442.jpg",
              },
            ],
            services: (
              <ul>
                <li>Fireplace</li>
                <li>Air conditioning</li>
                <li>Fully equipped kitchen</li>
                <li>Bathroom with shower</li>
                <li>Phone</li>
                <li>Satellite TV</li>
                <li>Veranda</li>
                <li>Garden</li>
                <li>Mountain View</li>
                <li>Ground floor</li>
                <li>Safe</li>
                <li>Hair dryer</li>
                <li>1 Double Bed</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Coffee maker</li>
                <li>Bath products</li>
                <li>Garden View</li>
                <li>2 sofa beds for 1 person</li>
              </ul>
            ),
          },
        ],
      },
      {
        id: 9,
        title: "Αγρόκτημα Πινακάδες",
        summary: (
          <div>
            <p>
              Το Αγρόκτημα "ΠΙΝΑΚΑΔΕΣ" εκτείνεται σε έκταση 44 στρεμμάτων, δίπλα
              στον Ποταμό Εύηνο μέσα σε μια σπάνια φυσική ομορφιά από πλατάνια
              και πεύκα. Βρίσκεται στο χωριό τρίκορφο Ναυπακτίας του Δήμου
              Χάλκειας πολύ κοντά στη Ναύπακτο και το Μεσολόγγι.
            </p>
            <p>
              {" "}
              Στο αγρόκτημα υπάρχουν 8 ανεξάρτητες επιπλωμένες κατοικίες απο
              Φιλανδικούς κορμούς, στάβλοι που φιλοξενούν 12 πανέμορφα άλογα και
              1 γαϊδουράκι, ελάφια που ζουν σε ειδικά φραγμένο χώρο, Ελληνικά
              ποιμενικά σκυλιά και πολλά ακόμη ζώα.
            </p>
          </div>
        ),
        rooms: [
          {
            id: 3,
            title: "Ξενωνας",
            price_breakdown: {
              all_inclusive_price: 70,
            },
            url: "http://www.pinakades.gr/kratiseis.html",
            adults: 4,
            children: true,
            images: [
              {
                id: 4,
                src: "http://www.pinakades.gr/images/2-crop-u1165.jpg?crc=112829569",
              },
              {
                id: 5,
                src: "http://www.pinakades.gr/images/5.jpg?crc=1582615",
              },
            ],
            services: (
              <ul>
                <li>Private fenced area for each house 20 m2. (garden)</li>
                <li>Enclosed common area with 5.5 acres of lawn</li>
                <li>Fully equipped kitchens</li>
                <li>Refrigerator</li>
                <li>TV - DVD - CD player - Radio</li>
                <li>Autonomous air conditioning (with hot/cold air)</li>
                <li>Radiator</li>
                <li>Fireplace</li>
                <li>Whirlpool Column</li>
                <li>Satelite television</li>
                <li>DVD Collection</li>
                <li>Living room</li>
                <li>Dinning room</li>
                <li>Daily cleaning and linen change every other day</li>
                <li>Board games</li>
                <li>Washing machine</li>
                <li>Iron and ironing board</li>
                <li>Hair dryer</li>
                <li>Security box</li>
                <li>Shaded parking for every cottage</li>
                <li>Aid Set</li>
                <li>Traditional food delivery to your home</li>
              </ul>
            ),
          },
        ],
      },
    ],
  },
  {
    id: 10,
    title: "Religious Tourism",
    rooms: false,
    item: [],
    activites: [
      {
        id: 20,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "550px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Women's Monastery of Agios Ioannis Prodromos Bombakos
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Women's Monastery:
              </strong>{" "}
              of Agios Ioannis Prodromos Bombokos
            </h4>

            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Starting the trek from the women's monastery of Agios Ioannis
              Prodromos Bombokos with interesting 17th century hagiographies, we
              proceed to the chronologically newest monastery of the
              Transfiguration of the Savior in Elato Square, built in 1864 by
              Epirote craftsmen, the Monastery of Panagia Gorgoepikou and ends
              on the western side of Municipality of Nafpaktia, the Byzantine
              church of Panagia Panaxiotissa and Mount Varasova, which during
              the Byzantine era was the "Holy Mountain of Aetolia" (due to the
              large number of monks and ascetics), with the characteristic
              enclosure of Agios Nikolaos perched on the rocks
            </p>
          </div>
        ),

        image:
          "https://www.visitnafpaktia.gr/wp-content/uploads/2021/11/silhouette-human-hand-holding-bible-cross-background-is-sunrise-1024x683.jpg",
      },
      {
        id: 22,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Panagia of Ambelakiotissa
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Panagia of Ambelakiotissa:
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              A point of reference for the area is the monastery of Panagia tis
              Ampelakiotissa (1456) near Ano Chora, which is considered the
              "Holy Ark of Nafpaktia" with relics of great religious and
              artistic value. There is kept the icon of the Virgin Mary, the
              work of the Evangelist Luke, as well as the altar with the right
              hand of Saint Polycarp. It is worth admiring the wood-carved
              iconostasis and iconostasis (1847) where the symbol of the vine
              dominates. The monastery is also decorated with the "Epitafios", a
              work of art embroidered in 1735 by Mariora.
            </p>
          </div>
        ),
        image:
          "https://www.visitnafpaktia.gr/wp-content/uploads/2021/12/panagia_ambelakiotissa.jpg",
      },

      {
        id: 23,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Varnakovas Monastery
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Varnakovas Monastery
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The monastery is located in southwestern Fokida, built on a hill
              at the foot of the Vardousia Mountains, among a dense forest of
              oaks and horse chestnuts, with a beautiful view. It is one of the
              oldest monastic centers in Greece, known for the miraculous icon
              of the Virgin Mary and dedicated to the Assumption of the Virgin
              Mary. It was founded in 1077 AD. from Saint Arsenius the
              Vernikovite. The emperors of Byzantium Komnenos as well as the
              despots of the Despotate of Epirus Angelos Komnenos and Paleologos
              associated their name with a series of dedications to the
              monastery.The icon of the Virgin dates back to the construction of
              the church and has a visible crack along the face of the Virgin,
              which according to eyewitnesses was created by a local earthquake
              that occurred on August 15, 1940, at the time of the torpedoing of
              the Helle in Tinos. In the Liberation Struggle of the Nation, the
              monastery offered invaluable services.n 1912, 96 monks and deacons
              lived in Varnakova. In 1984 it will be deserted, but since 1992 it
              has been reopened by a women's brotherhood. Today 10 nuns are
              serving.
            </p>
          </div>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_sotiros.jpg",
      },
      {
        id: 25,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Metamorfosis tou Sotiros{" "}
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Metamorfosis tou Sotiros
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Modern monastic community, on the eastern side of Skala village.
              It began to be built in 1977, relying mainly on voluntary
              offerings and the financial support of private individuals, as
              well as the work of the monks and friars, members of the monastic
              brotherhood. The complex today consists of the Church of the
              Transfiguration of the Savior, the common areas, the cells of the
              monks, the guest house, the large conference hall, its own radio
              station, hagiography, museum of Greek Orthodox tradition,
              Nafpaktos Naval Museum as well as a state-of-the-art fish farming
              unit for the needs of the monastery.The Brotherhood of the
              Monastery consists of young and qualified Monks, graduates of
              Higher - University Schools, while today it consists of twenty one
              members, who live communally. The festival of the monastery takes
              place on August 5 and 6, the day of the Transfiguration of the
              Savior.Panagia Nafpaktiotissa is also solemnly celebrated on the
              Tuesday of Diakainissimo after Easter, as well as on the 1st
              Sunday of October
            </p>
          </div>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_ag_ioanni.jpg",
      },
      {
        id: 26,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Monastery of St. Augustine & Seraphim Sarof Trikorfos
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Monastery of St. Augustine & Seraphim Sarof Trikorfos
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The Monastery of Saints Augustine Hipponos and Seraphim of Sarof
              is located in a landscape at 900m. altitude with a grove and many
              animals. It receives thousands of pilgrims every year, while it
              was also known in the past with the characteristic name
              "Paparokades". Initially, from 1986, the space was used for the
              youth camps of the Ilioupoli catechists, while in 1991 it was
              decided to build a men's monastery within the space. Great zeal
              was shown by the spiritual children of the elder Nektarios
              Moulatsiotis, inside and outside Greece. In 1994, the grand bell
              tower of the monastery with its 400 signs and 62 bells was
              inaugurated.The bells are rung every 5 years on the feast of Saint
              Seraphim, where 400 believers climb up the bell tower and rung
              them by hand. The 62 bells of the monastery all ring together on
              the feasts of Saint Augustine (June 15), Saint Seraphim (July 19,
              collection of relics), Saint Nektarios (November 9), the Dormition
              of the Blessed Virgin Mary, Saint Christopher (chapel), Christmas
              and Easter Eve.
            </p>
          </div>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_ag_ioanni.jpg",
      },
      {
        id: 27,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Church of Panagia Panaxiotissa
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Church of Panagia Panaxiotissa
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The Byzantine church of the Dormition of the Virgin Mary of
              Panaxiotissa is located in an idyllic landscape between olive
              trees and pine trees, 2 km north of Gavrolimni (DE of Chalkia), on
              the old road that connected Gavrolimni with Nafpaktos and next to
              an inn that gave its name to today's adjacent village "Chania
              Gavrolimnis". The word name, possibly unique in the Orthodox
              world, has been interpreted as "the one who deserved everything".
              The dating of the monument is placed by most researchers in the
              second half of the 10th century. A significant number of visitors
              gather every year for the divine service held on the second day of
              Easter. This is followed by welcoming treats and traditional
              dances.
            </p>
          </div>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_panaxiotissa.jpg",
      },
      {
        id: 28,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Holy Monastery of Agios Kosmas of Etolos
          </p>
        ),
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Holy Monastery of Agios Kosmas of Etolos
              </strong>
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Our Saint was the reformer of our Homeland, he saved the identity
              of our Nation, he was a modern Father of the Church, he offered
              ideals and values ​​to the family, to society. His educational and
              missionary work was great and his contribution to the enslaved
              race even greater. With his martyr's blood he watered the tree of
              Liberty and Orthodoxy. He is our Patron.
            </p>
          </div>
        ),
        image:
          "http://4.bp.blogspot.com/-sT-iOlHuPxs/U4l0CeqaLgI/AAAAAAAAK6U/_emIXU3Mla0/s1600/I.M.+Agiou+Kosma+Aitwlou.jpg",
      },
    ],
  },
  {
    id: 11,
    title: "Summer Tourism",
    rooms: false,
    item: [],
    activites: [
      {
        id: 29,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Venetian Castle
          </p>
        ),
        image:
          "https://www.greeka.com/seedo/photos/725/nafpaktos-venetian-castle-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                The Venetian Castle
              </strong>
              : The well preserved Castle is one of the most beautiful castles
              in Greece
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              It proudly keeps watch on the city and the bay of Patras and its
              picturesque ramparts go down to enclose the little harbor. Its
              fortification passed through many phases, as it was started in the
              ancient times, passed through the Venetian conquest and was
              completed by the Turks.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The castle protected the people during wars and was unique for its
              five defensive walls, two of which were built going down to the
              sea and three walls were constructed at different levels on the
              hill between the maritime walls and the castle for maximum
              security.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              In periods of sieges, people would get into the castle to protect
              their lives from the enemies. Through its history, it has been a
              base for conquerors and pirates.The Ozolian Locrians owned the
              castle at first, in the ancient times.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              At around 455 BC, the people of Athens sent the Messenian refugees
              there and made it their main naval station in western Greece
              during the Peloponnesian war. Two key battles took place and in
              404 BC it was returned to the Locrians.In 191 BC, the castle was
              yielded to the Romans after much fighting. In the sixth century,
              it was partly ruined by an earthquake.In the Middle Ages, it fell
              to the Venetians, who equipped it until the Turks got it in the
              fifteenth century.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The entrance to the Gulf was where a grand sea fight took place in
              1571, the battle of Lepanto as it is known, where the Ottoman
              Empire's naval power was almost totally damaged by the combined
              papal, Spanish, Habsburg and Venetian forces.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              After the end of the Revolution War, the castle and the town
              became part of the Greek state. Today, it is a famous
              archaeological site visited by many tourists every year.`,
            </p>
          </div>
        ),
      },
      {
        id: 30,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Statue of Cervantes
          </p>
        ),
        image:
          "https://www.greeka.com/seedo/photos/726/nafpaktos-statue-of-cervantes-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                The statue of Cervantes
              </strong>
              : Could it be that a European writer is honored in a small Greek
              town?
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Indeed, the statue of Miguel de Cervantes surprisingly stands in a
              park near the port in order to commemorate the participation of
              this great novelist into the sea battle, also known as the battle
              of Lepanto.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              This battle took place in October 1571 and was decisive for
              putting an end to the Turkish naval supremacy.Miguel de Cervantes
              was a great Spanish novelist, poet, and playwright. At the time of
              the battle, he was 23 years old and was serving the Spanish
              infantry.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Although Cervantes was taken down with fever, he refused to stay
              below and decided to take part in the battle, saying that he would
              rather die for his God and his king rather than hide under the
              blankets.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              During the fight, he got three gunshot wounds: two in the chest
              and one in his left arm, which paralyzed and was cut off.
              Cervantes used to say that he lost his left hand so that he could
              glorify his right hand, making, of course, a reference to his
              literary work.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Many of his military experiences are recorded in his famous novel
              Don Quixote.His brave fighting in the battle of Lepanto granted
              him this imposing iron statue, in which he is looking at a feather
              he is holding up with a proud gesture, as a representation of his
              distinguished literary work.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The inscription above the statue says Miguel de Cervantes Saavedra
              (1547-1616), a Spanish soldier, a genius of letters, honor of
              humanity, wounded heroically at the naval battle.
            </p>
          </div>
        ),
      },
      {
        id: 31,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tower of Botsaris
          </p>
        ),
        image:
          "https://www.greeka.com/seedo/photos/728/nafpaktos-tower-of-botsaris-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                The Tower of Botsaris
              </strong>
              :The Tower of Botsaris lies close to the Venetian Castle and the
              port
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              It is an impressive construction, which actually consists of two
              towers. The first tower, the west one, was built in the 15th
              century by Venetian builders. The east tower was built in the 16th
              century by the Turks.After the liberation of the city (18th April
              1829), this tower was bought by general Notis Botsaris, member of
              Botsaris family from Souli, Epirus, who contributed a lot to the
              Greek War of Independence. The tower belongs today to the
              Dimitrios and Egli Botsaris Institution and works as a museum. The
              ground of the tower hosts a permanent exhibition about the Battle
              of Lepanto (October 1571).
            </p>{" "}
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The exhibition constitutes 80 replicas of paintings and gravures,
              made by the most famous artists of that era.There are also special
              displays on western churches and portraits of western rulers of
              the medieval times. On the first floor, we also find paintings and
              gravures as well as wall textiles that represent the historical
              phases of Nafpaktos town. These wall textiles were weaved in
              Brussels by Jian-Andrea Doria, on the initial designs of Loukas
              Kabiazo. Botsaris Tower is today well-preserved and constitutes a
              favorite spot for the residents of Nafpaktos.
            </p>{" "}
          </div>
        ),
        location: { lat: 38.39314377710003, lng: 21.82703226377134 },
      },
      {
        id: 32,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Statue of Anemogiannis
          </p>
        ),
        image:
          "https://www.greeka.com/seedo/photos/1123/nafpaktos-statue-of-anemogiannis-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                The statue of Anemogiannis in Nafpaktos Greece
              </strong>
              : The statue of Giorgos Anemogiannis is found today on the western
              rampart near the port of Nafpaktos.
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Giorgos Anemogiannis (1796-1821) was born in Paxi island and took
              part in the deliberation of Nafpaktos from the Turks, during the
              Greek War of Independence. In May 1821, he tried to burn the
              Turkish fleet, moored in the harbor of Nafpaktos. However, he
              failed and got captured by the Turks, who killed him in a painful
              way.Because of his courage and contribution to the freedom of
              Greece, Giorgos Anemogiannis is considered to be a national hero,
              as he lost his life defending his country. Today, the statue in
              the port of Nafpaktos represents him holding a torch. There is
              also another statue in his honor in the southern entrance of Gaios
              harbor in his homeplace, Paxi island.
            </p>
          </div>
        ),
        location: { lat: 38.39202729055619, lng: 21.828867453363895 },
      },
      {
        id: 33,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Botanical Garden Zelios Gi
          </p>
        ),
        image:
          "https://www.greeka.com/seedo/photos/1488/nafpaktos-botanical-garden-zelios-gi-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                The Botanical Garden Zelios
              </strong>
              :Is ideal for spending time in nature and joining a variety of
              activities.
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              It is located 30 minutes from Nafpaktos by car. The Garden took a
              decade to create and it has been open to the public since 2019.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The 1000m2 estate includes gardens, vineyards and animal housing
              facilities. There are 6 beautifully arranged gardens in total,
              with over 3500 plants that are continuously growing. You will find
              various species of seasonal plants, flowers and herbs, as well as
              deer and other animals in an environment with a peaceful
              atmosphere.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Special events are regularly organized, including pottery or
              gardening and other special workshops for children that are based
              on the Montessori learning method.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              The opening hours are 09:00 - 17:00, from Thursday to Sunday.
            </p>
          </div>
        ),

        location: { lat: 38.39655991350073, lng: 21.607858622245015 },
      },
      {
        id: 34,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Kakkavos Bridge
          </p>
        ),
        type: "Monument",
        location: "Ano Hora",
        image: "",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Kakkavos Bridge
              </strong>
              :Built at the end of the 19th century. It is 11.10 m long and 2.50
              m wide.
            </h4>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              In its place, there used to be a wooden bridge, but it was swept
              away by the rushing waters. The entire bridge is built of local
              stone.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              As a binding material, "murzoula" was used, a mixture of sand and
              a material similar to today's cement. On the cobbled road of the
              path passed travelers, mule drivers, pilgrims and pilgrims of I.M.
              Ambelakiotissa. During the Occupation approximately 3,000 Italians
              crossed the path passing through Agrinio in the direction of
              Amfissa. The walk takes place in a green landscape, full of rare
              plant species, next to the waters of the Katsalo river, which
              crosses the Kakkavos gorge.
            </p>{" "}
          </div>
        ),
        location: { lat: 38.65695927733723, lng: 21.90972071861728 },
      },
      {
        id: 35,
        title: (
          <p
            style={{
              fontSize: "24px",
              color: "#0f59ad",
              borderBottom: "2px solid rgb(255, 195, 65)",
              maxWidth: "250px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Town Beach
          </p>
        ),
        image:
          "https://www.greeka.com/village_beach/photos/1858/town-beach-top-1-1280.webp",
        summary: (
          <div>
            <h4
              style={{
                fontSize: "24px",
                fontFamily: "Times New Roma",
              }}
            >
              <strong
                style={{
                  fontSize: "24px",
                  fontFamily: "Times New Roma",
                  borderBottom: "1px solid #0f59ad",
                }}
              >
                Nafpaktos beach
              </strong>
              :Partly-Organized, Family Friendly, Pebbled
            </h4>

            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              When talking about Nafpaktos beach, we actually refer to two
              beaches. The port of Nafpaktos divides the beachfront into two
              beaches: the beach of Psani in the Western area and the beach of
              Gribovo in the eastern.
            </p>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roma",
              }}
            >
              Both of them are organized, very clean and offer many convenient
              facilities such as showers, changing rooms and lifeguards, facts
              that have awarded them the Blue Flag. For more information on
              these two beaches, please refer to the separate pages of Gribovo
              Beach and Psani Beach.
            </p>
          </div>
        ),
      },
    ],
  },
];
export const alternativeEl = [
  {
    id: 1,
    title: "Agritourism",
    rooms: true,
    activites: [
      {
        id: 12,
        title: (
          <p>
            Ιππεύστε δίπλα στη θάλασσα, σε λίμνες ή στην εξοχή με τη βοήθεια
            συνοδών. Τα ιππικά κέντρα βρίσκονται μέσα στους ξενώνες είτε σε
            απόσταση έως 10 χλμ
          </p>
        ),
      },
      {
        di: 13,
        title: (
          <p>
            Περπατήστε και εξερευνήστε χαρτογραφημένα μονοπάτια, με τη συνοδεία
            των ιδιοκτητών, εξειδικευμένων οδηγών ή μόνοι σας, με αφετηρία τον
            τόπο διαμονής σας ή σε απόσταση έως 10 χλμ
          </p>
        ),
      },
      {
        id: 14,
        title: (
          <p>
            Διαμονή κοντά σε ποτάμια όπου θα έχετε την ευκαιρία να διασκεδάσετε
            κάνοντας κανό, καγιάκ ή ράφτινγκ με συνοδεία έμπειρων οδηγών σε
            απόσταση έως 15 χλμ. από τον ξενών
          </p>
        ),
      },
      {
        id: 15,
        title: (
          <p>
            Κάντε ποδηλασία και εξερευνήστε την ελληνική ύπαιθρο και τις
            ομορφιές της. Διαθέσιμα ποδήλατα θα βρείτε στον ξενώνα ή σε απόσταση
            έως 5 χλμ
          </p>
        ),
      },
      {
        id: 17,
        title: (
          <p>
            Παρατηρήστε μοναδικά είδη αποδημητικών πτηνών σε προστατευμένους
            υγροβιότοπους σε απόσταση έως 15 χλμ. από τον τόπο διαμονής σας
          </p>
        ),
      },
      {
        id: 18,
        title: (
          <p>
            Μείνετε σε αγρόκτημα, ανακαλύψτε γεωργικές δραστηριότητες και
            δοκιμάσετε τοπικά προϊόντα. Ελάτε κοντά στη φύση και ζήστε αξέχαστες
            διακοπές
          </p>
        ),
      },
      {
        id: 19,
        title: (
          <p>
            Ζήστε τη συναρπαστική εμπειρία της αναρρίχησης. Οι ιδιοκτήτες θα σας
            δώσουν όλες τις απαραίτητες πληροφορίες αλλά όχι και τον εξοπλισμό
          </p>
        ),
      },
    ],
    item: [
      {
        id: 2,
        title: "EVINOS ORGANIC GARDEN-FARM",
        summary: (
          <div>
            <p>
              Στα μέσα της διαδρομής Μεσολογίου-Ναυπάκτου, το βιολογικό
              αγρόκτημα-κήπος “Evinos Garden” με ελαιώνες και οπωροφόρα δέντρα,
              βρίσκεται σε ένα ήρεμο φυσικό περιβάλλον ιδανικό για χαλαρωτικές
              διακοπές μακριά από το άγχος της καθημερινότητας. Θερμό
              καλωσόρισμα και ζεστή οικογενειακή ατμόσφαιρα.
            </p>
            <p>
              Το αγρόκτημα 8 στρεμμάτων διαθέτει 4 παραδοσιακές πετρόχτιστες
              κατοικίες τοπικής αρχιτεκτονικής, στούντιο και διαμερίσματα γύρω
              από μια πισίνα. Η θέα που προσφέρουν στους γύρω λόφους και την
              μοναδική κοίτη του ποταμού Ευήνου είναι εκπληκτική.
            </p>
            <p>
              Λειτουργικά και πλήρως εξοπλισμένα, μπορούν να φιλοξενήσουν από 2
              έως 4 άτομα άνετα.
            </p>
            <p>
              Οι επισκέπτες μπορούν να ξεκινήσουν τη μέρα τους με ένα γευστικό
              πρωινό που αποτελείται από βιολογικά (όσο είναι δυνατό) προϊόντα
              που παράγονται στο κτήμα ή από τοπικούς παραγωγούς.`,
            </p>
          </div>
        ),
        rooms: [
          {
            id: 3,
            title: "Miguel De Cervantes House 39 τ.μ",
            price_breakdown: {
              all_inclusive_price: 45,
            },
            adults: 4,
            children: true,
            url: "https://guestinn.com/en/property/evinos_garden",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/ec33f6abb1ebb8f3e8f4b102740b2fc9.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/17ae5ad128e99111a7084e51c743dfda.jpg",
              },
            ],
            services: (
              <ul>
                <li>Τζάκι </li>
                <li> Κλιματισμός</li>
                <li>Πλήρως Εξοπλισμένη κουζίνα </li>
                <li> Μπάνιο με ντους</li>
                <li>Τηλέφωνο </li>
                <li>Δορυφορική TV </li>
                <li>Βεράντα </li>
                <li>Κήπος </li>
                <li>Θέα Βουνό </li>
                <li>Ισόγειο </li>
                <li>Χρηματοκιβώτιο </li>
                <li>Πιστολάκι για μαλλιά </li>
                <li>1 Διπλό κρεβάτι </li>
                <li>Καθιστικό </li>
                <li>Τραπεζαρία </li>
                <li>Καφετιέρα </li>
                <li>Προϊόντα μπάνιου </li>
                <li>Θέα κήπο </li>
                <li>2 καναπέ-κρεβάτι για 1 άτομο </li>
              </ul>
            ),
          },
          {
            id: 4,
            title: "Charilaos Trikoupis House 41 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 47,
            },
            children: true,
            url: "https://guestinn.com/en/property/evinos_garden",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/2847c0ef5fdf67581175507209574706.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/15a6228686842a78a92151128ddedf90.jpg",
              },
            ],
            services: (
              <ul>
                <li>Τζάκι </li>
                <li> Κλιματισμός</li>
                <li>Πλήρως Εξοπλισμένη κουζίνα </li>
                <li> Μπάνιο με ντους</li>
                <li>Τηλέφωνο </li>
                <li>Δορυφορική TV </li>
                <li>Βεράντα </li>
                <li>Κήπος </li>
                <li>Θέα Βουνό </li>
                <li>Ισόγειο </li>
                <li>Χρηματοκιβώτιο </li>
                <li>Πιστολάκι για μαλλιά </li>
                <li>1 Διπλό κρεβάτι </li>
                <li>Καθιστικό </li>
                <li>Τραπεζαρία </li>
                <li>Καφετιέρα </li>
                <li>Προϊόντα μπάνιου </li>
                <li>Θέα κήπο </li>
                <li>2 καναπέ-κρεβάτι για 1 άτομο </li>
              </ul>
            ),
          },
          {
            id: 5,
            title: "Deianira Studio 31 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 52,
            },
            children: true,
            url: "https://guestinn.com/en/property/evinos_garden",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/ac12746fa6284381f660c69073f8bb8a.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/17ae5ad128e99111a7084e51c743dfda.jpg",
              },
            ],
            services: (
              <ul>
                <li>Τζάκι </li>
                <li> Κλιματισμός</li>
                <li>Πλήρως Εξοπλισμένη κουζίνα </li>
                <li> Μπάνιο με ντους</li>
                <li>Τηλέφωνο </li>
                <li>Δορυφορική TV </li>
                <li>Βεράντα </li>
                <li>Κήπος </li>
                <li>Θέα Βουνό </li>
                <li>Ισόγειο </li>
                <li>Χρηματοκιβώτιο </li>
                <li>Πιστολάκι για μαλλιά </li>
                <li>1 Διπλό κρεβάτι </li>
                <li>Καθιστικό </li>
                <li>Τραπεζαρία </li>
                <li>Καφετιέρα </li>
                <li>Προϊόντα μπάνιου </li>
                <li>Θέα κήπο </li>
                <li>2 καναπέ-κρεβάτι για 1 άτομο </li>
              </ul>
            ),
          },
          {
            id: 6,
            title: "Lord Byron House 41 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 55,
            },
            children: true,
            url: "https://guestinn.com/en/property/evinos_garden",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/318b806b2121e267e99ded9e70a63b41.jpg",
              },
              {
                id: 5,
                src: "https://guestinn.com/properties/9OY/92fd476f6fb50a54dae1ba3425ca1974.jpg",
              },
            ],
            services: (
              <ul>
                <li>Τζάκι </li>
                <li> Κλιματισμός</li>
                <li>Πλήρως Εξοπλισμένη κουζίνα </li>
                <li> Μπάνιο με ντους</li>
                <li>Τηλέφωνο </li>
                <li>Δορυφορική TV </li>
                <li>Βεράντα </li>
                <li>Κήπος </li>
                <li>Θέα Βουνό </li>
                <li>Ισόγειο </li>
                <li>Χρηματοκιβώτιο </li>
                <li>Πιστολάκι για μαλλιά </li>
                <li>1 Διπλό κρεβάτι </li>
                <li>Καθιστικό </li>
                <li>Τραπεζαρία </li>
                <li>Καφετιέρα </li>
                <li>Προϊόντα μπάνιου </li>
                <li>Θέα κήπο </li>
                <li>2 καναπέ-κρεβάτι για 1 άτομο </li>
              </ul>
            ),
          },
          {
            id: 7,
            title: "Giannis Vlachogiannis House 47 τ.μ",
            adults: 4,
            price_breakdown: {
              all_inclusive_price: 65,
            },
            children: true,
            url: "https://guestinn.com/en/property/evinos_garden",
            images: [
              {
                id: 4,
                src: "https://guestinn.com/properties/9OY/b8b17c99ea65dea6a3955ddd870757be.jpg",
              },
              {
                id: 5,
                src: "https://cdn.webhotelier.net/photos/w=1920/evinosgard-gianni/L215442.jpg",
              },
            ],
            services: (
              <ul>
                <li>Τζάκι </li>
                <li> Κλιματισμός</li>
                <li>Πλήρως Εξοπλισμένη κουζίνα </li>
                <li> Μπάνιο με ντους</li>
                <li>Τηλέφωνο </li>
                <li>Δορυφορική TV </li>
                <li>Βεράντα </li>
                <li>Κήπος </li>
                <li>Θέα Βουνό </li>
                <li>Ισόγειο </li>
                <li>Χρηματοκιβώτιο </li>
                <li>Πιστολάκι για μαλλιά </li>
                <li>1 Διπλό κρεβάτι </li>
                <li>Καθιστικό </li>
                <li>Τραπεζαρία </li>
                <li>Καφετιέρα </li>
                <li>Προϊόντα μπάνιου </li>
                <li>Θέα κήπο </li>
                <li>2 καναπέ-κρεβάτι για 1 άτομο </li>
              </ul>
            ),
          },
        ],
      },
      {
        id: 9,
        title: "Αγρόκτημα Πινακάδες",
        summary: `Το Αγρόκτημα "ΠΙΝΑΚΑΔΕΣ" εκτείνεται σε έκταση 44 στρεμμάτων, δίπλα στον Ποταμό Εύηνο μέσα σε μια σπάνια φυσική ομορφιά από πλατάνια και πεύκα. Βρίσκεται στο χωριό τρίκορφο Ναυπακτίας του Δήμου Χάλκειας πολύ κοντά στη Ναύπακτο και το Μεσολόγγι. Στο αγρόκτημα υπάρχουν 8 ανεξάρτητες επιπλωμένες κατοικίες απο Φιλανδικούς κορμούς, στάβλοι που φιλοξενούν 12 πανέμορφα άλογα και 1 γαϊδουράκι, ελάφια που ζουν σε ειδικά φραγμένο χώρο, Ελληνικά ποιμενικά σκυλιά και πολλά ακόμη ζώα.`,
        rooms: [
          {
            id: 3,
            title: "Ξενωνας",
            price_breakdown: {
              all_inclusive_price: 70,
            },
            adults: 4,
            children: true,
            url: "http://www.pinakades.gr/kratiseis.html",
            images: [
              {
                id: 4,
                src: "http://www.pinakades.gr/images/2-crop-u1165.jpg?crc=112829569",
              },
              {
                id: 5,
                src: "http://www.pinakades.gr/images/5.jpg?crc=1582615",
              },
            ],
            services: (
              <ul>
                <li>
                  Ιδιωτικός περιφραγμένος χώρος για κάθε σπιτακι 20 m2.(κήπος)
                </li>
                <li>Περιφραγμένος κοινός χώρος με γκαζόν 5,5 στρεμάτων</li>
                <li>Πλήρως εξοπλισμένες κουζίνες</li>
                <li>Ψυγείο</li>
                <li>Tηλεόραση - DVD - CD player - Ραδιόφωνο</li>
                <li>Αυτόνομος κλιματισμός(με θερμό /ψυχρό αέρα)</li>
                <li>Καλοριφερ</li>
                <li>Τζάκι</li>
                <li>Υδρομασάζ στήλη</li>
                <li>Δορυφορική τηλεόραση</li>
                <li>Συλλογή DVD</li>
                <li>Σαλόνι</li>
                <li>Τραπεζαρία</li>
                <li>
                  Καθημερινή καθαριότητα και αλλαγή λευκών ειδών κάθε δεύτερη
                  μέρα
                </li>
                <li>Επιτραπέζια παιχνίδια</li>
                <li>Σίδερο και σιδερώστρα</li>
                <li>Σεσουάρ για τα μαλλιά</li>
                <li>Πλυντήριο ρούχων</li>
                <li>Θυρίδα ασφαλείας</li>
                <li>Σκιερό πάρκινγκ για κάθε σπιτάκι</li>
                <li>Σετ Α’ βοηθειών</li>
                <li>Παράδοση παραδοσιακού φαγητού στο σπιτάκι σας</li>
              </ul>
            ),
          },
        ],
      },
    ],
  },
  {
    id: 10,
    title: "Religious Tourism",
    rooms: false,
    item: [],
    activites: [
      {
        id: 20,
        title: <p>Γυναικεία μονή του Αγίου Ιωάννη του Προδρόμου Βομβοκούς</p>,
        summary: (
          <p>
            Ξεκινώντας το οδοιπορικό από τη γυναικεία μονή του Αγίου Ιωάννη του
            Προδρόμου Βομβοκούς με ενδιαφέρουσες αγιογραφίες του 17ου αιώνα,
            προχωράμε στο νεότερο χρονολογικά μοναστήρι της Μεταμορφώσεως του
            Σωτήρος στην πλατεία Ελάτου, κτισμένο το 1864 από Ηπειρώτες
            μάστορες, τη Μονή Παναγίας Γοργοεπηκόου και καταλήγει στην δυτική
            πλευρά του Δήμου Ναυπακτίας τη βυζαντινή εκκλησία της Παναγίας της
            Παναξιώτισσας και το όρος Βαράσοβα, που κατά τη βυζαντινή εποχή ήταν
            το «Άγιο Όρος της Αιτωλίας» (λόγω του μεγάλου αριθμού μοναχών και
            ασκηταριών), με τη χαρακτηριστική εγκλείστρα του Αγίου Νικολάου
            σκαρφαλωμένη στα βράχια.
          </p>
        ),

        image:
          "https://www.visitnafpaktia.gr/wp-content/uploads/2021/11/silhouette-human-hand-holding-bible-cross-background-is-sunrise-1024x683.jpg",
      },
      {
        id: 22,
        title: <p>Παναγίας της Αμπελακιώτισσας</p>,
        summary: (
          <p>
            Σημείο αναφοράς της περιοχής είναι και η μονή της Παναγίας της
            Αμπελακιώτισσας (1456) κοντά στην Άνω Χώρα, η οποία θεωρείται η
            «Ιερά κιβωτός της Ναυπακτίας» με κειμήλια μεγάλης θρησκευτικής και
            καλλιτεχνικής αξίας. Εκεί φυλάσσεται η εικόνα της Θεοτόκου, έργο του
            Ευαγγελιστή Λουκά, καθώς και η λάρνακα με το δεξί χέρι του Αγίου
            Πολύκαρπου. Αξίζει να θαυμάσει κανείς το ξυλόγλυπτο εικονοστάσι και
            τέμπλο (1847) όπου κυριαρχεί το σύμβολο της αμπέλου. Το μοναστήρι
            κοσμεί επίσης ο «Επιτάφιος», έργο τέχνης που κεντήθηκε το 1735 από
            την Μαριώρα.
          </p>
        ),
        image:
          "https://www.visitnafpaktia.gr/wp-content/uploads/2021/12/panagia_ambelakiotissa.jpg",
      },

      {
        id: 23,
        titlte: <p>Μονή Βαρνάκοβας</p>,
        summary: (
          <p>
            Η μονή βρίσκεται στη νοτιοδυτική Φωκίδα, κτισμένη σε έναν λόφο στις
            παρυφές των Βαρδουσίων Ορέων, ανάμεσα σε πυκνό δάσος από δρύες και
            αγριοκαστανιές, με ωραία θέα. Είναι ένα από τα αρχαιότερα μοναστικά
            κέντρα της Ελλάδας, γνωστό για τη θαυματουργή εικόνα της Παναγίας
            και αφιερωμένο στην Κοίμηση της Θεοτόκου. Ιδρύθηκε το 1077 μ.Χ. από
            τον Όσιο Αρσένιο τον Βερνικοβίτη. Οι αυτοκράτορες του Βυζαντίου
            Κομνηνοί καθώς επίσης οι δεσπότες του Δεσποτάτου της Ηπείρου Άγγελοι
            Κομνηνοί και Παλαιολόγοι συνέδεσαν το όνομά τους με μια σειρά
            αφιερώσεων στο μοναστήρι. Η εικόνα της Παναγίας χρονολογείται από
            κτίσεως του ναού και φέρει εμφανές ράγισμα κατά μήκος του προσώπου
            της Θεοτόκου, το οποίο σύμφωνα με αυτόπτες μάρτυρες δημιουργήθηκε
            από τοπικό σεισμό που συνέβη στις 15 Αυγούστου 1940, την ώρα του
            τορπιλισμού της Έλλης στην Τήνο. Στον Απελευθερωτικό Αγώνα του
            Έθνους, το μοναστήρι προσέφερε ανεκτίμητες υπηρεσίες. Το 1912 ζούσαν
            στη Βαρνάκοβα 96 ιερομόναχοι και διάκονοι. Το 1984 θα ερημωθεί, αλλά
            από το 1992 επαναλειτουργεί από γυναικεία αδελφότητα. Σήμερα
            υπηρετούν 10 μοναχές.
          </p>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_sotiros.jpg",
      },
      {
        id: 25,
        title: <p>Μονή Μεταμορφώσεως του Σωτήρος</p>,
        summary: (
          <p>
            Σύγχρονη μοναστική κοινότητα, στην ανατολική πλευρά του χωριού
            Σκάλα. Άρχισε να κτίζεται το 1977 βασιζόμενο κυρίως σε εθελοντικές
            προσφορές και την οικονομική συμπαράσταση ιδιωτών, καθώς και στην
            εργασία των μοναχών και ιερομονάχων, μελών της μοναστικής
            αδελφότητος. Το συγκρότημα σήμερα αποτελείται από το ναό της
            Μεταμόρφωσης του Σωτήρος, τους κοινούς χώρους, τα κελιά των μοναχών,
            τον ξενώνα, τη μεγάλη αίθουσα συνεδρίων, δικό του ραδιοφωνικό
            σταθμό, αγιογραφείο, μουσείο ελληνορθόδοξης παράδοσης, μουσείο
            Ναυμαχίας της Ναυπάκτου αλλά και μια υπερσύγχρονη μονάδα
            ιχθυοκαλλιέργειας για τις ανάγκες της μονής. Η Αδελφότητα του
            Μοναστηριού αποτελείται από νέους και καταρτισμένους Μοναχούς,
            πτυχιούχους Ανωτάτων – Πανεπιστημιακών Σχολών, ενώ, σήμερα
            απαρτίζεται από είκοσι ένα μέλη, που ζουν κοινοβιακά. Το πανηγύρι
            του μοναστηριού γίνεται στις 5 και 6 Αυγούστου, ημέρα της
            Μεταμορφώσεως του Σωτήρος. Επίσης εορτάζεται πανηγυρικά η Παναγία η
            Ναυπακτιώτισσα, την Τρίτη της Διακαινισίμου μετά το Πάσχα, καθώς και
            την 1η Κυριακή του Οκτωβρίου.
          </p>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_ag_ioanni.jpg",
      },
      {
        id: 26,
        title: <p>Μονή Αγ. Αυγουστίνου & Σεραφείμ Σαρώφ Τρικόρφου</p>,
        summary: (
          <p>
            Η Μονή Αγίων Αυγουστίνου Ίππωνος και Σεραφείμ του Σάρωφ βρίσκεται σε
            ένα τοπίο στα 900μ. υψόμετρο με άλσος και πολλά ζώα. Δέχεται
            χιλιάδες προσκυνητές κάθε χρόνο, ενώ είχε γίνει γνωστό παλαιότερα
            και με το χαρακτηριστικό όνομα «Παπαροκάδες». Αρχικά, από το 1986, ο
            χώρος χρησιμοποιήθηκε για τις νεανικές κατασκηνώσεις των κατηχητικών
            της Ηλιούπολης, ενώ το 1991 αποφασίστηκε να χτιστεί μια ανδρική μονή
            εντός του χώρου. Μεγάλο ζήλο επέδειξαν τα πνευματικά παιδιά του
            γέροντα Νεκταρίου Μουλατσιώτη, εντός και εκτός Ελλάδος. Το 1994
            έγιναν και τα εγκαίνια του μεγάλου καμπαναριού της μονής με τα 400
            σήμαντρα και τις 62 καμπάνες. Τα σήμαντρα κτυπούν κάθε 5 χρόνια στην
            εορτή του Αγίου Σεραφείμ, όπου ανεβαίνουν επάνω στο καμπαναριό 400
            πιστοί και τα κτυπούν με το χέρι. Οι 62 καμπάνες της μονής κτυπούν
            όλες μαζί στις εορτές του Αγίου Αυγουστίνου (15 Ιουνίου), του Αγίου
            Σεραφείμ (19 Ιουλίου, ανακομιδή λειψάνων), του Αγίου Νεκταρίου (9
            Νοεμβρίου), στην Κοίμηση της Υπεραγίας Θεοτόκου, του Αγίου
            Χριστοφόρου (παρεκκλήσι), τα Χριστούγεννα και το βράδυ της
            Ανάστασης.
          </p>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_ag_ioanni.jpg",
      },
      {
        id: 27,
        title: <p>Ναός Παναγίας Παναξιώτισσας</p>,
        summary: (
          <p>
            Ο βυζαντινός ναός της Κοίμησης της Θεοτόκου της Παναξιώτισσας
            βρίσκεται σε ειδυλλιακό τοπίο ανάμεσα σε ελιές και πεύκα, 2 χλμ
            βόρεια της Γαυρολίμνης (ΔΕ Χάλκειας), πάνω στον παλαιό δρόμο που
            ένωνε τη Γαυρολίμνη με τη Ναύπακτο και δίπλα σε χάνι που έδωσε το
            όνομα στο σημερινό παρακείμενο χωριό “Χάνια Γαυρολίμνης”. Η λόγια
            επωνυμία, πιθανόν μοναδική στον ορθόδοξο χώρο έχει ερμηνευθεί ως
            «αυτήν που αξιώθηκε των πάντων». Η χρονολόγηση του μνημείου
            τοποθετείται από τους περισσότερους ερευνητές στο β' μισό του 10ου
            αι. Σημαντικός αριθμός επισκεπτών συγκεντρώνεται κάθε χρόνο στη θεία
            λειτουργία που τελείται τη δεύτερη ημέρα του Πάσχα. Ακολουθούν
            φιλόξενα κεράσματα και παραδοσιακοί χοροί.
          </p>
        ),
        image:
          "https://www.treantonafpaktos.gr/images/sights/treantonafpaktos_monastiria_panaxiotissa.jpg",
      },
      {
        id: 28,
        title: <p>Ιερά Μονή Αγίου Κοσμά του Αιτωλού</p>,
        summary: (
          <p>
            Ο Άγιός μας υπήρξε ο αναμορφωτής της Πατρίδας μας, διέσωσε την
            ταυτότητα του Γένους μας, υπήρξε ένας σύγχρονος Πατέρας της
            Εκκλησίας, πρόσφερε ιδανικά και αξίες στην οικογένεια, στην
            κοινωνία. Το μορφωτικό και ιεραποστολικό του έργο ήταν μεγάλο και η
            προσφορά του στο σκλαβωμένο γένος ακόμα μεγαλήτερη. Με το μαρτυρικό
            του αίμα πότισε το δένδρο της Λευτεριάς και της Ορθοδοξίας. Είναι ο
            Προστάτης μας.
          </p>
        ),
        image:
          "http://4.bp.blogspot.com/-sT-iOlHuPxs/U4l0CeqaLgI/AAAAAAAAK6U/_emIXU3Mla0/s1600/I.M.+Agiou+Kosma+Aitwlou.jpg",
      },
    ],
  },
  {
    id: 11,
    title: "Summer Tourism",
    rooms: false,
    item: [],
    activites: [
      {
        id: 29,
        title: <p>Το Βενετσιάνικο Κάστρο</p>,
        image:
          "https://www.greeka.com/seedo/photos/725/nafpaktos-venetian-castle-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Το Ενετικό Κάστρο</strong>: Το καλοδιατηρημένο Κάστρο
              είναι ένα από τα τα πιο όμορφα κάστρα στην Ελλάδα
            </h4>
            <p>
              {" "}
              Παρακολουθεί περήφανα την πόλη και τον κόλπο της Πάτρας και οι
              γραφικές επάλξεις κατεβαίνουν για να περικλείσουν το λιμανάκι. Η
              οχύρωση πέρασε από πολλές φάσεις, η οποία ξεκίνησε στους αρχαίους
              χρόνους, πέρασε από την ενετική κατάκτηση και ήταν συμπλήρωσαν οι
              Τούρκοι.
            </p>
            <p>
              Το κάστρο προστάτευε τους ανθρώπους κατά τη διάρκεια των πολέμων
              και ήταν μοναδικό για τα πέντε αμυντικά τείχη του, δύο από τα
              οποία χτίστηκαν κατεβαίνοντας στη θάλασσα και τρία τείχη
              κατασκευάστηκαν σε διαφορετικά επίπεδα, ο λόφος ανάμεσα στα
              θαλάσσια τείχη και το κάστρο για το μέγιστο ασφάλεια.
            </p>
            <p>
              Σε περιόδους πολιορκιών, οι άνθρωποι έμπαιναν στο κάστρο για να
              προστατεύουν τη ζωή τους από τους εχθρούς. Μέσα από την ιστορία
              του, αυτό υπήρξε ορμητήριο κατακτητών και πειρατών.Οι Οζολοί
              Λοκροί κατείχαν το κάστρο στην αρχή, στην αρχαιότητα.
            </p>
            <p>
              {" "}
              Γύρω στο 455 π.Χ., οι Αθηναίοι έστειλαν τους Μεσσήνιους πρόσφυγες
              εκεί και το έκαναν τον κύριο ναυτικό τους σταθμό της δυτικής
              Ελλάδας κατά τον Πελοποννησιακό πόλεμο. Δόθηκαν δύο βασικές μάχες
              και το 404 π.Χ επιστράφηκε στους Λοκρούς.Το 191 π.Χ Το κάστρο
              παραδόθηκε στους Ρωμαίους μετά από πολλές μάχες. Στον έκτος αιώνα,
              καταστράφηκε εν μέρει από σεισμό, περιήλθε στους Ενετούς, οι
              οποίοι το εξόπλισαν μέχρι που Οι Τούρκοι το πήραν τον δέκατο
              πέμπτο αιώνα.
            </p>
            <p>
              Η είσοδος στον Κόλπο ήταν εκεί που έλαβε χώρα ένας μεγαλειώδης
              θαλάσσιος αγώνας το 1571, η μάχη του Λεπάντου όπως είναι γνωστό,
              όπου η Οθωμανική ναυτική δύναμη καταστράφηκε σχεδόν ολοσχερώς από
              το συνδυασμό των παπικών, ισπανικών, αψβουργικών και βενετικών
              δυνάμεις.
            </p>
            <p>
              Μετά το τέλος του Πολέμου της Επανάστασης, το κάστρο και η πόλη
              έγινε μέρος του ελληνικού κράτους. Σήμερα, είναι ένας διάσημος
              αρχαιολογικός χώρος που επισκέπτονται πολλοί τουρίστες κάθε χρόνο.
            </p>
          </div>
        ),
      },
      {
        id: 30,
        title: <p>Άγαλμα του Θερβάντες</p>,
        image:
          "https://www.greeka.com/seedo/photos/726/nafpaktos-statue-of-cervantes-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Το Άγαλμα Μιγκέλ Θερβάντες</strong>: Θα μπορούσε να
              τιμάται ένας Ευρωπαίος συγγραφέας σε μια μικρή ελληνική πόλη?
            </h4>
            <p>
              Πράγματι, το άγαλμα του Μιγκέλ ντε Θερβάντες στέκεται εκπληκτικά
              σε ένα πάρκο κοντά στο λιμάνι για να τιμήσει τη συμμετοχή αυτού
              του μεγάλου μυθιστοριογράφου στη θαλάσσια μάχη, γνωστή και ως μάχη
              του Λεπάντο.
            </p>
            <p>
              {" "}
              Αυτή η μάχη έλαβε χώρα τον Οκτώβριο του 1571 και ήταν καθοριστική
              για τον τερματισμό της τουρκικής ναυτικής υπεροχής. Ο Μιγκέλ ντε
              Θερβάντες ήταν σπουδαίος Ισπανός μυθιστοριογράφος, ποιητής και
              θεατρικός συγγραφέας. Την ώρα της μάχης ήταν 23 ετών και
              υπηρετούσε το ισπανικό πεζικό.
            </p>
            <p>
              {" "}
              Αν και ο Θερβάντες έπεσε με πυρετό, αρνήθηκε να μείνει κάτω και
              αποφάσισε να συμμετάσχει στη μάχη, λέγοντας ότι προτιμούσε να
              πεθάνει για τον Θεό και τον βασιλιά του παρά να κρυφτεί κάτω από
              τις κουβέρτες.
            </p>
            <p>
              {" "}
              Κατά τη διάρκεια της μάχης, έφερε τρία τραύματα από πυροβολισμό:
              δύο στο στήθος και ένα στο αριστερό του χέρι, το οποίο παρέλυσε
              και του κόπηκε. Ο Θερβάντες έλεγε ότι έχασε το αριστερό του χέρι
              για να δοξάσει το δεξί του, κάνοντας βέβαια αναφορά στο
              λογοτεχνικό του έργο.
            </p>
            <p>
              Πολλές από τις στρατιωτικές του εμπειρίες καταγράφονται στο
              διάσημο μυθιστόρημά του Δον Κιχώτης. Η γενναία μάχη του στη μάχη
              του Lepanto του χάρισε αυτό το επιβλητικό σιδερένιο άγαλμα, στο
              οποίο κοιτάζει ένα φτερό που κρατά ψηλά με μια περήφανη
              χειρονομία, όπως μια αναπαράσταση του διακεκριμένου λογοτεχνικού
              του έργου.
            </p>
            <p>
              Η επιγραφή πάνω από το άγαλμα λέει τον Miguel de Cervantes
              Saavedra (1547-1616), έναν Ισπανό στρατιώτη, μια ιδιοφυΐα των
              γραμμάτων, τιμή της ανθρωπότητας, που τραυματίστηκε ηρωικά στη
              ναυμαχία.
            </p>
          </div>
        ),
      },
      {
        id: 31,
        title: <p>Ο Πύργος Μπότσαρη</p>,
        image:
          "https://www.greeka.com/seedo/photos/728/nafpaktos-tower-of-botsaris-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Ο Πύργος του Μπότσαρη</strong>Ο Πύργος του Μπότσαρη
              βρίσκεται κοντά στο Ενετικό Κάστρο και το λιμάνι
            </h4>
            <p>
              Είναι μια εντυπωσιακή κατασκευή, η οποία ουσιαστικά αποτελείται
              από δύο πύργους. Ο πρώτος πύργος, ο δυτικός, χτίστηκε τον 15ο
              αιώνα από Βενετούς οικοδόμους. Ο ανατολικός πύργος χτίστηκε στο
              16ος αιώνας από τους Τούρκους.Μετά την απελευθέρωση της πόλης
              (18ος Απρίλιος 1829), αυτός ο πύργος αγοράστηκε από τον στρατηγό
              Νότη Μπότσαρη, μέλος της οικογένειας Μπότσαρη από το Σούλι της
              Ηπείρου, που συνέβαλε α πολλά στον Ελληνικό Πόλεμο της
              Ανεξαρτησίας. Ο πύργος ανήκει σήμερα σε το Ίδρυμα Δημήτριος και
              Έγκλη Μπότσαρη και εργάζεται ως α μουσείο. Στο έδαφος του πύργου
              φιλοξενείται μόνιμη έκθεση για τη μάχη του Λεπάντο (Οκτώβριος
              1571).
            </p>
            <p>
              Η έκθεση αποτελείται από 80 αντίγραφα ζωγραφικής και γκραβούρες,
              φτιαγμένες από τους πιο γνωστούς καλλιτέχνες εκείνης της εποχής.
              Υπάρχουν επίσης ειδικές εκθέσεις σε δυτικές εκκλησίες και πορτρέτα
              του δυτικοί ηγεμόνες των μεσαιωνικών χρόνων. Στον πρώτο όροφο,
              εμείς βρείτε επίσης πίνακες ζωγραφικής και γκραβούρες καθώς και
              υφάσματα τοίχου που αντιπροσωπεύουν τις ιστορικές φάσεις της πόλης
              της Ναυπάκτου. Αυτοί οι τοίχοι υφαντά ύφαιναν στις Βρυξέλλες ο
              Jian-Andrea Doria, στο αρχικά σχέδια του Λουκά Καμπιάζο. Ο Πύργος
              Μπότσαρη είναι σήμερα καλοδιατηρημένο και αποτελεί αγαπημένο
              σημείο για τους κατοίκους Ναυπάκτου.
            </p>
          </div>
        ),
        location: { lat: 38.39314377710003, lng: 21.82703226377134 },
      },
      {
        id: 32,
        title: <p>Το Άγαλμα του Ανεμογιάννη</p>,
        image:
          "https://www.greeka.com/seedo/photos/1123/nafpaktos-statue-of-anemogiannis-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Το άγαλμα του Ανεμογιάννη στη Ναύπακτο.</strong>Το άγαλμα
              του Γιώργου Ανεμογιάννη βρίσκεται σήμερα στον δυτικό προμαχώνα
              κοντά στο λιμάνι της Ναυπάκτου.{" "}
            </h4>
            <p>
              Ο Γιώργος Ανεμογιάννης (1796-1821) γεννήθηκε στους Παξούς και
              συμμετείχε στη διαβούλευση της Ναυπάκτου από τους Τούρκους, κατά
              τη διάρκεια της Ελληνικής Επανάστασης. Τον Μάιο του 1821
              προσπάθησε να κάψει τον τουρκικό στόλο, που ήταν αγκυροβολημένος
              στο λιμάνι της Ναυπάκτου. Ωστόσο, απέτυχε και συνελήφθη από τους
              Τούρκους, οι οποίοι τον σκότωσαν με οδυνηρό τρόπο. Λόγω του
              θάρρους και της προσφοράς του στην ελευθερία της Ελλάδας, ο
              Γιώργος Ανεμογιάννης θεωρείται εθνικός ήρωας, καθώς έχασε τη ζωή
              του υπερασπιζόμενος την πατρίδα του. Σήμερα, το άγαλμα στο λιμάνι
              της Ναυπάκτου τον αναπαριστά να κρατά μια δάδα. Υπάρχει επίσης ένα
              άλλο άγαλμα προς τιμήν του στη νότια είσοδο του λιμανιού του Γάιου
              στην πατρίδα του, το νησί των Παξών.
            </p>
          </div>
        ),
        location: { lat: 38.39202729055619, lng: 21.828867453363895 },
      },
      {
        id: 33,
        title: <p>Ζέλιος Γη: Βοτανικός Κήπος</p>,
        image:
          "https://www.greeka.com/seedo/photos/1488/nafpaktos-botanical-garden-zelios-gi-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Ζέλιος Γη: Βοτανικός Κήπος</strong>:Είναι ιδανικό για να
              περάσετε χρόνο στη φύση και να συμμετάσχετε σε ποικίλες
              δραστηριότητες.
            </h4>
            <p>
              Βρίσκεται 30 λεπτά από τη Ναύπακτο με το αυτοκίνητο. Η δημιουργία
              του Garden χρειάστηκε μια δεκαετία και είναι ανοιχτός στο κοινό
              από το 2019.
            </p>
            <p>
              {" "}
              Το κτήμα 1000m2 περιλαμβάνει κήπους, αμπελώνες και εγκαταστάσεις
              στέγασης ζώων. Υπάρχουν 6 όμορφα διαρρυθμισμένοι κήποι συνολικά,
              με πάνω από 3500 φυτά που αναπτύσσονται συνεχώς. Θα βρείτε διάφορα
              είδη εποχιακών φυτών, λουλουδιών και βοτάνων, καθώς και ελάφια και
              άλλα ζώα σε ένα περιβάλλον με γαλήνια ατμόσφαιρα.
            </p>
            <p>
              {" "}
              Οργανώνονται τακτικά ειδικές εκδηλώσεις, συμπεριλαμβανομένων
              αγγειοπλαστικής ή κηπουρικής και άλλα ειδικά εργαστήρια για παιδιά
              που βασίζονται στη μέθοδο μάθησης Montessori.
            </p>
            <p>
              {" "}
              Οι ώρες λειτουργίας είναι 09:00 - 17:00, από Πέμπτη έως Κυριακή.
            </p>
          </div>
        ),
        location: { lat: 38.39655991350073, lng: 21.607858622245015 },
      },
      {
        id: 34,
        title: <p>Γεφύρι του Κάκκαβου</p>,
        type: "Monument",
        location: "Ano Hora",
        image: "",
        summary: (
          <div>
            <h4>
              <strong>Το “Γεφύρι του Κάκκαβου“: </strong>Χτίστηκε στα τέλη του
              19ου αι.Εχει μήκος 11,10 μ. και πλάτος 2,50 μ.
            </h4>
            <p>
              Στην θέση του, παλαιότερα, υπήρχε ξύλινο γεφύρι το οποίο όμως
              παρασύρθηκε από τα ορμητικά νερά. Ολόκληρο το γεφύρι είναι
              χτισμένο από πέτρα της περιοχής.
            </p>
            <p>
              Σαν συνδετικό υλικό χρησιμοποιήθηκε η «μουρτζουλάνα», μείγμα άμμου
              και μιας ύλης αντίστοιχης με το σημερινό τσιμέντο. Από το
              λιθόστρωτο δρόμο του μονοπατιού περνούσαν ταξιδιώτες, αγωγιάτες με
              τα μουλάρια τους, πραματευτάδες και προσκυνητές της Ι.Μ.
              Αμπελακιώτισσας. Στην Κατοχή περίπου 3.000 Ιταλοί διέσχισαν το
              μονοπάτι διερχόμενοι από το Αγρίνιο με κατεύθυνση την Άμφισσα. Ο
              περίπατος γίνεται μέσα σε ένα καταπράσινο τοπίο, γεμάτο από σπάνια
              είδη φυτών, δίπλα στα νερά του ποταμού Κάτσαλο, που διασχίζει το
              φαράγγι του Κάκκαβου.
            </p>
          </div>
        ),
        location: { lat: 38.65695927733723, lng: 21.90972071861728 },
      },
      {
        id: 35,
        title: <p>Παραλία μέσα στην Πόλη της Ναυπάκτου</p>,
        image:
          "https://www.greeka.com/village_beach/photos/1858/town-beach-top-1-1280.webp",
        summary: (
          <div>
            <h4>
              <strong>Παραλία στην Πόλη της Ναυπάκτου</strong>:Μερικώς
              Οργανωμένη, Οικογενειακή Φιλικό, Παραλία με βότσαλα{" "}
            </h4>
            <p>
              Όταν μιλάμε για την παραλία της Ναυπάκτου, στην πραγματικότητα
              αναφερόμαστε σε δύο παραλίες. Το λιμάνι της Ναυπάκτου χωρίζει την
              παραλία στα δύο παραλίες: η παραλία της Ψάνης στη Δυτική περιοχή
              και η παραλία της Γρίμποβο στα ανατολικά.
            </p>
            <p>
              Και οι δύο είναι οργανωμένα, πολύ καθαρές και προσφέρουν πολλές
              βολικές εγκαταστάσεις όπως ντους, αποδυτήρια και ναυαγοσώστες,
              γεγονότα που τους έχουν απονείμει τη Γαλάζια Σημαία.{" "}
            </p>
          </div>
        ),
      },
    ],
  },
];
export const foodanddrinkEn = [
  {
    title: "Antirrio",
    items: [
      {
        id: 1,
        title: "To Maistrali",
        descriptionFull:
          "This place is a hidden paradise if you look for quality sea food and peaceful surroundingsVery fresh fish always cooked in the best way at really budget price within a wonderful environment . ",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g3228880-d12124299-Reviews-To_Maistrali-Antirrio_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 2,
        title: "Kondilis",
        descriptionFull:
          "Wide range of baklava and cookie items, some for immediate consumption, some for later. They have a roof top seating area to enjoy the views. Very clean, friendly service.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g3228880-d14035000-Reviews-Kondilis-Antirrio_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },
  {
    title: "Nafpaktos",
    items: [
      {
        id: 6,
        title: "Vida",
        descriptionFull:
          "Vida Bar is located in the harbor of Nafpaktos offering a beautiful view to its guests. This bar organizes various DJ music events, parties, and Greek nights that attract many people. Vida is open every day from 10:00 to 04:00 in the morning. It is an ideal option whether you wish to have a cup of coffee or to have a drink by the sound of good music.",
        url: "https://www.facebook.com/vidabarnafpaktos/?locale=el_GR",
      },
      {
        id: 7,
        title: "Taratsa 360 wine bar",
        descriptionFull:
          "Taratsa 360 wine bar became an integral part of Nafpaktos' nightlife right away. With its cozy character and its unique inner space, which has established it as one of the most distinct spots around the area, especially in the summer, it perfectly matches the city's cobbled streets, keeping the fun alive until the first morning hours.",
        url: "https://www.facebook.com/taratsa360/",
      },

      {
        id: 8,
        title: "Centro Porto Cafe",
        descriptionFull:
          "Centraki - Centro Porto became an integral part of Nafpaktos' nightlife right away. With its cozy character and its unique inner space, which has established it as one of the most distinct spots around the area, especially in the summer, it perfectly matches the city's cobbled streets, keeping the fun alive until the first morning hours.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d8509005-Reviews-Centro_Porto-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 9,
        title: "Living Room Restaurant",
        descriptionFull: `Special beverages and delicious dishes perfectly prepared by this modern restaurant in Nafpaktos. A chance to taste original Greek cuisine flavors and discover a whole new variety of coffee blends and drink mixtures.

            The Living Room's team has created a friendly place for both winter and summer days, with a long menu listing fresh salads, imaginative pasta recipes and fulfilling snacks.`,
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d6883352-Reviews-Living_Room_Gastro_Bar_Restaurant-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },

      {
        id: 10,
        title: "Evoinos",
        descriptionFull:
          "Evoinos tavern can be found in Nafpaktos, at one of its most beautiful places called stenopazaro. A great variety of delicious treats and traditional meze bites that satisfy every demand for good, quality food. Easy to find, next to the renowned port of Nafpaktos.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d3566596-Reviews-Ev_Oinos-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 11,
        title: "Kouzina Loi",
        descriptionFull:
          "The restaurant Kouzina Maria Loi in Nafpaktos operates since 2003. Every recipe and every single dish is a different, new dining experience. The picturesque surroundings and the beautiful atmosphere of the restaurant create memorable senses, gaining impressions at every level.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d3577015-Reviews-Kouzina_Loi-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 12,
        title: "Captain Cook",
        descriptionFull:
          "Newly opened restaurant in the beautiful port of Nafpaktos, offering a relaxing sea view. Open kitchen and wonderful Mediterranean and Greek cuisine, including local specialties.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d12419598-Reviews-Captain_Cook-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 13,
        title: "Papoulis",
        image: "",
        descriptionFull: `Papoulis is a Greek restaurant located in Nafpaktos, where guests can taste Mediterranean recipes. It has a very cozy environment along with a lovely view of the sea. Papoulis has gained a lot of good reviews from its customers because of its food quality.

        The menu includes delicious appetizers and main dishes, especially seafood, such as fish, calamari, and pasta with shrimps. The opening hours of Papoulis are from Monday to Friday from 11:00 until 02:00 in the early morning. Also, the restaurant’s staff is very friendly and the service is quite fast.`,
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d5049443-Reviews-Papoulis-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },
  {
    title: "Ano Hora",
    items: [
      {
        id: 14,
        title: "Moschoula Restaurant",
        descriptionFull:
          "Amazing Family-run tavern with several tasteful dishes. Delicious Home-Cooked meals, prepared by the Amazing Mrs Moschoula herself! Andrew & Spyros (father & son), always cheerful, polite & eager to offer invaluable insights about the surrounding forests & mountain paths, making the overall experience a great family memory to cherish.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d13118619-Reviews-Moschoula_Restaurant-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 15,
        title: "Panorama",
        descriptionFull:
          "Excellent homemade food, wine,sweets and normal prices! Fantastic place and balcony view! Very kind stuff! Everything perfect! We will visit “Panorama” again for sure!",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d12172125-Reviews-Panorama-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 16,
        title: "Taverna Patouhas Vasilios",
        descriptionFull:
          "Very convenient location, excellent food, extremely friendly place. Ask for the home made stuffed pies!",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d7993706-Reviews-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },
  {
    title: "Arahova",
    items: [
      {
        id: 18,
        title: "Fterolaka Restaurant",
        descriptionFull:
          "Fterolaka tavern in the center of Arachova offers top quality food prepared in the traditional way and with the purest ingredients. The stunning indoor and outdoor decoration create an absolutely idyllic atmosphere to enjoy a great meal.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d6981487-Reviews-Ffterolakka-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 19,
        title: "Kaplanis",
        descriptionFull:
          "Where tradition meets the modern conveniences of the 21st century; in Arachova, of course, and moreover at the old well-known hang-out, the Plane Tree. Kaplanis extends his hospitality with his well known traditional and classy cuisine. Excellent service, selective specialties, a cuisine which has reached its zenith.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d5534384-Reviews-Kaplanis-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 20,
        title: "Arachova O Tea Pineis Cafe in Town",
        descriptionFull:
          "O Tea Pineis is situated in the center of Arachova with a long list of types of tea, aromatic coffee, chocolates, herbs, and honey jars. Also, it is a source of nuts and superfood; goji berries, chia, cocoa and many others. All of these gathered in a brand new, warm and modern place.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d13001797-Reviews-O_Tea_Pineis-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 21,
        title: "Arachova Barile Bar in Town",
        descriptionFull:
          "Barile Espresso-Wine bar is one of the best places in Arachova to enjoy unique brunch, delicious dishes and selected labels of wines in a cozy indoor space with modern decoration.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d11982934-Reviews-Barile_Resto_Vino_Bar-Arachova_Boeotia_Region_Central_Greece.html",
      },
    ],
  },

  {
    title: "Pilini",
    items: [],
  },
];
export const foodanddrinkEl = [
  {
    title: "Antirrio",
    items: [
      {
        id: 1,
        title: "Το Μαϊστράλι",
        descriptionFull:
          "Αυτό το μέρος είναι ένας κρυμμένος παράδεισος αν αναζητάτε ποιοτικά θαλασσινά και ήσυχο περιβάλλον. Πολύ φρέσκο ​​ψάρι μαγειρεμένο πάντα με τον καλύτερο τρόπο σε πραγματικά οικονομική τιμή σε ένα υπέροχο περιβάλλον.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g3228880-d12124299-Reviews-To_Maistrali-Antirrio_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 2,
        title: "Κονδύλης",
        descriptionFull:
          "Μεγάλη γκάμα από μπακλαβά και μπισκότα, άλλα για άμεση κατανάλωση, άλλα για αργότερα. Διαθέτουν καθιστικό στον τελευταίο όροφο για να απολαύσετε τη θέα. Πολύ καθαρό, φιλική εξυπηρέτηση.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g3228880-d14035000-Reviews-Kondilis-Antirrio_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },
  {
    title: "Nafpaktos",
    items: [
      {
        id: 6,
        title: "Vida Βαr",
        descriptionFull:
          "Το Vida Bar βρίσκεται στο λιμάνι της Ναυπάκτου και προσφέρει υπέροχη θέα στους επισκέπτες του. Αυτό το μπαρ διοργανώνει διάφορες μουσικές εκδηλώσεις DJ, πάρτι και ελληνικές βραδιές που προσελκύουν πολύ κόσμο. Το Vida είναι ανοιχτό καθημερινά από τις 10:00 έως τις 04:00 το πρωί. Είναι ιδανική επιλογή είτε θέλετε να πιείτε ένα φλιτζάνι καφέ είτε να πιείτε ένα ποτό υπό τον ήχο της καλής μουσικής.",
        url: "https://www.facebook.com/vidabarnafpaktos/?locale=el_GR",
      },
      {
        id: 7,
        title: "Ταράτσα 360 wine bar",
        descriptionFull:
          "Το Taratsa 360 wine bar έγινε αμέσως αναπόσπαστο κομμάτι της νυχτερινής ζωής της Ναυπάκτου. Με τον φιλόξενο χαρακτήρα του και τον μοναδικό εσωτερικό του χώρο, που το έχει καθιερώσει ως ένα από τα πιο ξεχωριστά σημεία της περιοχής, ειδικά το καλοκαίρι, ταιριάζει απόλυτα με τα πλακόστρωτα δρομάκια της πόλης, κρατώντας τη διασκέδαση ζωντανή μέχρι τις πρώτες πρωινές ώρες.",
        url: "https://www.facebook.com/taratsa360/",
      },

      {
        id: 8,
        title: "Centro Porto Cafe",
        descriptionFull:
          "Το Centraki - Centro Porto έγινε αμέσως αναπόσπαστο κομμάτι της νυχτερινής ζωής της Ναυπάκτου. Με τον φιλόξενο χαρακτήρα του και τον μοναδικό εσωτερικό του χώρο, που το έχει καθιερώσει ως ένα από τα πιο ξεχωριστά σημεία της περιοχής, ειδικά το καλοκαίρι, ταιριάζει απόλυτα με τα πλακόστρωτα δρομάκια της πόλης, κρατώντας τη διασκέδαση ζωντανή μέχρι τις πρώτες πρωινές ώρες.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d8509005-Reviews-Centro_Porto-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 9,
        title: "Living Room Restaurant",
        descriptionFull: `Ιδιαίτερα ποτά και νόστιμα πιάτα παρασκευάζονται τέλεια από αυτό το μοντέρνο εστιατόριο στη Ναύπακτο. Μια ευκαιρία να δοκιμάσετε αυθεντικές γεύσεις ελληνικής κουζίνας και να ανακαλύψετε μια εντελώς νέα ποικιλία από μείγματα καφέ και μείγματα ποτών.

        Η ομάδα του Living Room έχει δημιουργήσει ένα φιλικό μέρος τόσο για τις μέρες του χειμώνα όσο και για το καλοκαίρι, με ένα μεγάλο μενού που περιλαμβάνει φρέσκες σαλάτες, ευφάνταστες συνταγές ζυμαρικών και χορταστικά σνακ.`,
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d6883352-Reviews-Living_Room_Gastro_Bar_Restaurant-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },

      {
        id: 10,
        title: "Εύοινος",
        descriptionFull:
          "Η ταβέρνα Εύοινος βρίσκεται στη Ναύπακτο, σε ένα από τα πιο όμορφα μέρη της που ονομάζεται στενόπαζαρο. Μεγάλη ποικιλία από νόστιμα κεράσματα και παραδοσιακούς μεζέδες που ικανοποιούν κάθε απαίτηση για καλό, ποιοτικό φαγητό. Εύκολο να βρεθεί, δίπλα στο φημισμένο λιμάνι της Ναυπάκτου.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d3566596-Reviews-Ev_Oinos-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 11,
        title: "Κουζίνα Loi",
        descriptionFull:
          "Το εστιατόριο Κουζίνα Μαρία Λόη στη Ναύπακτο λειτουργεί από το 2003. Κάθε συνταγή και κάθε πιάτο είναι μια διαφορετική, νέα γευστική εμπειρία. Το γραφικό περιβάλλον και η όμορφη ατμόσφαιρα του εστιατορίου δημιουργούν αξέχαστες αισθήσεις, κερδίζοντας τις εντυπώσεις σε κάθε επίπεδο.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d3577015-Reviews-Kouzina_Loi-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 12,
        title: "Captain Cook",
        descriptionFull:
          "Πρόσφατα άνοιξε εστιατόριο στο όμορφο λιμάνι της Ναυπάκτου, με χαλαρωτική θέα στη θάλασσα. Ανοιχτή κουζίνα και υπέροχη μεσογειακή και ελληνική κουζίνα, συμπεριλαμβανομένων τοπικών σπεσιαλιτέ.",
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d12419598-Reviews-Captain_Cook-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 13,
        title: "Παπούλης",
        image: "",
        descriptionFull: `Το Παπούλης είναι ένα ελληνικό εστιατόριο που βρίσκεται στη Ναύπακτο, όπου οι επισκέπτες μπορούν να δοκιμάσουν μεσογειακές συνταγές. Έχει ένα πολύ ζεστό περιβάλλον με υπέροχη θέα στη θάλασσα. Ο Παπουλής έχει κερδίσει πολλές καλές κριτικές από τους πελάτες του λόγω της ποιότητας του φαγητού του.

        Το μενού περιλαμβάνει νόστιμα ορεκτικά και κυρίως πιάτα, κυρίως θαλασσινά, όπως ψάρια, καλαμάρια και ζυμαρικά με γαρίδες. Το ωράριο λειτουργίας του Papoulis είναι από Δευτέρα έως Παρασκευή από τις 11:00 έως τις 02:00 τα ξημερώματα. Επίσης, το προσωπικό του εστιατορίου είναι πολύ φιλικό και η εξυπηρέτηση είναι αρκετά γρήγορη.`,
        url: "https://www.tripadvisor.com.gr/Restaurant_Review-g635603-d5049443-Reviews-Papoulis-Naupactus_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },

  {
    title: "Ano Hora",
    items: [
      {
        id: 14,
        title: "Εστιατόριο Μοσχούλα",
        descriptionFull:
          "Καταπληκτική οικογενειακή ταβέρνα με πολλά καλόγουστα πιάτα. Νόστιμα σπιτικά φαγητά, που ετοιμάζει η ίδια η Καταπληκτική κυρία Μοσχούλα! Andrew & Spyros (πατέρας & γιος), πάντα χαρούμενοι, ευγενικοί και πρόθυμοι να προσφέρουν ανεκτίμητες γνώσεις για τα γύρω δάση και τα μονοπάτια των βουνών, κάνοντας τη συνολική εμπειρία μια υπέροχη οικογενειακή ανάμνηση.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d13118619-Reviews-Moschoula_Restaurant-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 15,
        title: "Πανόραμα",
        descriptionFull:
          "Εξαιρετικό σπιτικό φαγητό, κρασί, γλυκά και κανονικές τιμές! Φανταστικό μέρος και θέα στο μπαλκόνι! Πολύ ευγενικά πράγματα! Όλα τέλεια! Θα επισκεφθούμε ξανά το «Πανόραμα» σίγουρα!",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d12172125-Reviews-Panorama-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
      {
        id: 16,
        title: "Ταβέρνα Πατούχας Βασίλειος",
        descriptionFull:
          "Πολύ βολική τοποθεσία, εξαιρετικό φαγητό, εξαιρετικά φιλικό μέρος. Ζητήστε τις σπιτικές γεμιστές πίτες!",
        url: "https://www.tripadvisor.com/Restaurant_Review-g2144195-d7993706-Reviews-Ano_Chora_Aetolia_Acarnania_Region_West_Greece.html",
      },
    ],
  },
  {
    title: "Arahova",
    items: [
      {
        id: 18,
        title: "Ταβέρνα Φτερόλακας",
        descriptionFull:
          "Η ταβέρνα Φτερόλακα στο κέντρο της Αράχωβας προσφέρει κορυφαίας ποιότητας φαγητά παρασκευασμένα με τον παραδοσιακό τρόπο και με τα πιο αγνά υλικά. Η εκπληκτική εσωτερική και εξωτερική διακόσμηση δημιουργούν μια απολύτως ειδυλλιακή ατμόσφαιρα για να απολαύσετε ένα υπέροχο γεύμα.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d6981487-Reviews-Ffterolakka-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 19,
        title: "Καπλάνης",
        descriptionFull:
          "Εκεί που η παράδοση συναντά τις σύγχρονες ανέσεις του 21ου αιώνα. στην Αράχωβα φυσικά και επιπλέον στο παλιό γνωστό στέκι, τον Πλάτανο. Ο Καπλάνης επεκτείνει τη φιλοξενία του με τη γνωστή παραδοσιακή και αριστοκρατική κουζίνα του. Εξαιρετική εξυπηρέτηση, εκλεκτές σπεσιαλιτέ, μια κουζίνα που έχει φτάσει στο ζενίθ της.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d5534384-Reviews-Kaplanis-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 20,
        title: "Ο Tea Πίνεις",
        descriptionFull:
          "Το Ο Tea Πίνεις βρίσκεται στο κέντρο της Αράχωβας με μια μεγάλη λίστα με είδη τσαγιού, αρωματικού καφέ, σοκολατάκια, βότανα και βαζάκια με μέλι. Επίσης, είναι πηγή ξηρών καρπών και υπερτροφής.Goji berries, chia, κακάο και πολλά άλλα. Όλα αυτά συγκεντρωμένα σε έναν ολοκαίνουργιο, ζεστό και μοντέρνο χώρο.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d13001797-Reviews-O_Tea_Pineis-Arachova_Boeotia_Region_Central_Greece.html",
      },
      {
        id: 21,
        title: "Barile Bar",
        descriptionFull:
          "Το Barile Espresso-Wine bar είναι ένα από τα καλύτερα μέρη στην Αράχωβα για να απολαύσετε μοναδικά brunch, νόστιμα πιάτα και επιλεγμένες ετικέτες κρασιών σε έναν ζεστό εσωτερικό χώρο με μοντέρνα διακόσμηση.",
        url: "https://www.tripadvisor.com/Restaurant_Review-g230066-d11982934-Reviews-Barile_Resto_Vino_Bar-Arachova_Boeotia_Region_Central_Greece.html",
      },
    ],
  },

  {
    title: "Pilini",
    items: [],
  },
];
