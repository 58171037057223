import { useState, lazy, Suspense, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
import { alternativeEn, alternativeEl } from "./utilities/NafpaktosAlternative";
/// Lay Outs ////
import MainLayOut from "./layouts/MainLayOut";
import ProfileLayOut from "./layouts/ProfileLayOut";

////////////////

// Pages //
const Homepage = lazy(() => import("./pages/HomePage/HomePage"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Platform = lazy(() => import("./pages/Platform/Platform"));
const Booking = lazy(() => import("./pages/Booking/Booking"));
const BookingDetails = lazy(() =>
  import("./pages/BookingDetails/BookingDetails")
);
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Login = lazy(() => import("./pages/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const RetrievePassword = lazy(() =>
  import("./pages/RetrievePassword/RetrievePassword")
);
const Register = lazy(() => import("./pages/Register/Register"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Cookies = lazy(() => import("./pages/Cookies/Cookies"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
///////////
function GetLocalStorageLanguage() {
  if (
    typeof window != "undefined" &&
    localStorage.getItem("lang") != "undefined"
  ) {
    localStorage.setItem("lang", JSON.stringify("El"));
  }
}
function App() {
  useEffect(() => {
    GetLocalStorageLanguage();
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Homepage />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/aboutus"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <AboutUs />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/platform"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Platform />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/createpackage"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Booking />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/createpackage/details"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <BookingDetails />
              </MainLayOut>
            </Suspense>
          }
        />

        <Route
          path="/contactus"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <ContactUs />
              </MainLayOut>
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Login />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <ForgotPassword />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/retrievepassword/:email"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <RetrievePassword />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Register />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <Suspense fallback={<Loader />}>
              <ProfileLayOut>
                <Profile />
              </ProfileLayOut>
            </Suspense>
          }
        />

        <Route
          path="/terms"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Terms />
              </MainLayOut>
            </Suspense>
          }
        />
        <Route
          path="/cookiesPolicy"
          element={
            <Suspense fallback={<Loader />}>
              <MainLayOut>
                <Cookies />
              </MainLayOut>
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default App;
