import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { VscThreeBars } from "react-icons/vsc";
import { ImCross } from "react-icons/im";
import { Link, useParams } from "react-router-dom";
import { HeaderLinks, HeaderLinksEng } from "../../utilities/HeaderLinks";
import logo from "./../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/Slices/UserSlice";
function Navbar() {
  const user = useSelector((state) => state.User.user);
  const Lang = useSelector((state) => state.Lang.lang);
  const dispatch = useDispatch();
  const [linkActive, setLinkActive] = useState("/");
  const [navActive, setNavActive] = useState(true);
  const [responsiveWidth, setResponsiveWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : null
  );

  const [responsiveHeight, setResponsiveHeight] = useState(
    typeof window !== "undefined" ? window.scrollY : null
  );
  function LogOut() {
    dispatch(logOut());
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleWindowResize = () => {
        setResponsiveWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleWindowResize);
      if (responsiveWidth > 992) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, [window.innerWidth]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleWindowResize = () => {
        setResponsiveHeight(window.scrollY);
      };

      window.addEventListener("scroll", handleWindowResize);

      return () => {
        window.removeEventListener("scroll", handleWindowResize);
      };
    }
  }, [window.scrollY]);
  useEffect(() => {
    setLinkActive(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <nav
      className={
        responsiveHeight >= 50
          ? `${style.Navbar} ${style.NavbarActive}`
          : style.Navbar
      }
    >
      <div className={style.lowerNav}>
        <div className={style.left_side_navbar_component}>
          <div className={style.logo_area}>
            <Link to="">
              {" "}
              <img src={logo} alt="Company Logo" className={style.logo_img} />
            </Link>
          </div>
          <div className={style.navbar_collapse}>
            {navActive ? (
              <ImCross onClick={() => setNavActive(!navActive)} />
            ) : (
              <VscThreeBars onClick={() => setNavActive(!navActive)} />
            )}
          </div>
        </div>
        <div
          className={navActive ? style.navbar_links : style.navbar_links_hidden}
        >
          {Lang == "En"
            ? HeaderLinksEng.map((link) => (
                <Link
                  to={link.link}
                  key={link.id}
                  className={
                    linkActive == link.link
                      ? `${style.navbar_link} ${style.navlinkActive}`
                      : style.navbar_link
                  }
                  onClick={() => {
                    if (responsiveWidth < 992) {
                      setNavActive(false);
                    }

                    setLinkActive(window.location.pathname);
                  }}
                >
                  {link.title}
                </Link>
              ))
            : HeaderLinks.map((link) => (
                <Link
                  to={link.link}
                  key={link.id}
                  className={
                    linkActive == link.link
                      ? `${style.navbar_link} ${style.navlinkActive}`
                      : style.navbar_link
                  }
                  onClick={() => {
                    if (responsiveWidth < 992) {
                      setNavActive(false);
                    }
                    setLinkActive(window.location.pathname);
                  }}
                >
                  {link.title}
                </Link>
              ))}
          {user ? (
            <button className={style.register} onClick={LogOut}>
              LogOut
            </button>
          ) : Lang == "En" ? (
            <>
              <button className={style.login}>
                <Link to="/login">Login</Link>
              </button>

              <button className={style.register}>
                <Link to="/register">Register</Link>
              </button>
            </>
          ) : (
            <>
              <button className={style.login}>
                <Link to="/login">Σύνδεση</Link>
              </button>

              <button className={style.register}>
                <Link to="/register">Εγγραφή</Link>
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
