import React from "react";
import style from "./MainLayOut.module.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/NavBar/Navbar";
function MainLayOut({ children }) {
  return (
    <>
      <Header />
      <Navbar />
      <div className={style.child}>{children}</div>
      <Footer />
    </>
  );
}

export default MainLayOut;
