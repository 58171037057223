import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotels: [],
  package: [],
};

const HotelSlice = createSlice({
  name: "Hotels",
  initialState,
  reducers: {
    getHotels: (state, action) => {
      state.hotels = action.payload;
    },
    getPackage: (state, action) => {
      console.log(action.payload);
      state.package.push(action.payload);
    },
    clearPackage: (state, action) => {
      state.package = [];
    },
  },
});

export const { getHotels, getPackage, clearPackage } = HotelSlice.actions;

export default HotelSlice.reducer;
