import React from "react";
import style from "./ProfileLayOut.module.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SideNavigation from "../components/SideNavigation/SideNavigation";
function ProfileLayOut({ children }) {
  return (
    <>
      <Header />
      <div className={style.layOut}>
        <SideNavigation />
        <div className={style.child}>{children}</div>
      </div>

      <Footer />
    </>
  );
}

export default ProfileLayOut;
