import React, { useEffect } from "react";
import style from "./SideNavigation.module.scss";
import { Link, useNavigate } from "react-router-dom";
import image from "./../../assets/carousel-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/Slices/UserSlice";
function SideNavigation({ userimage, userName, handleLogOut }) {
  const Auth = useSelector((state) => state.User.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(Auth);
  function Logout() {
    dispatch(logOut());
    navigate("/");
  }
  return (
    <nav className={style.sideNav}>
      <div className={style.sideNav_user_info}>
        <div className={style.image}>
          <img src={image} alt="user Image" />
        </div>
        <p>{Auth?.user.username}</p>
        <p>{Auth?.user.email}</p>
      </div>
      <div className={style.sideNav_list}>
        <ul className={style.list}>
          <li className={style.list_item}>
            <Link to="/">Αρχική</Link>
          </li>
          {/* <li className={style.list_item}>
            <Link to="/profile#booking_info">Πληροφορίες Κράτησης</Link>
          </li>
          <li className={style.list_item}>
            <Link to="/profile#travel_scedule">Πρόγραμμα ταξιδιού</Link>
          </li> */}

          <button onClick={Logout}>Log Out</button>
        </ul>
      </div>
    </nav>
  );
}

export default SideNavigation;
