import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import { CgProfile } from "react-icons/cg";
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getLang } from "../../redux/Slices/LanguageSlice";
import greekImage from "./../../assets/greekflag.png";
import ukImage from "./../../assets/ukflag.png";
function Header() {
  const dispatch = useDispatch();
  return (
    <header className={style.upperNav}>
      <div className={style.contact}>
        <span className={style.tel}>tel:6934394416</span>
        <span className={style.email}>e-mail:greenandbluedev@gmail.com</span>
      </div>
      <div className={style.social}>
        <span className={style.facebook}>
          <BsFacebook />
        </span>
        <span className={style.twitter}>
          <BsTwitter />
        </span>
        <span className={style.linkedIn}>
          <BsLinkedin />
        </span>
      </div>
      <div className={style.languages}>
        <div>
          <img
            onClick={() => dispatch(getLang("En"))}
            src={ukImage}
            className={style.ukflag}
          />
        </div>

        <div>
          <img
            onClick={() => dispatch(getLang("El"))}
            src={greekImage}
            className={style.greekflag}
          />
        </div>
      </div>
      <div className={style.user_profile}>
        <Link to="/profile">
          <CgProfile />
        </Link>
      </div>
    </header>
  );
}

export default Header;
