import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserReducer from "./Slices/UserSlice";
import HotelReducer from "./Slices/HotelSlice";
import LanguageReducer from "./Slices/LanguageSlice";
import FormReducer from "./Slices/FormInputs";
const reducers = combineReducers({
  User: UserReducer,
  Hotels: HotelReducer,
  Lang: LanguageReducer,
  Form: FormReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
