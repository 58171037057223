export const HeaderLinks = [
  { id: 1, title: "Αρχική", link: "/" },
  { id: 2, title: "Πλατφόρμα", link: "/platform" },
  { id: 3, title: "Η ΚοινΣεπ μας", link: "/aboutus" },
  { id: 4, title: "Δημιουργία Πακέτου", link: "/createpackage" },
  { id: 5, title: "Επικοινωνία", link: "/contactus" },
];
export const HeaderLinksEng = [
  { id: 1, title: "Home", link: "/" },
  { id: 2, title: "Our Platform", link: "/platform" },
  { id: 3, title: "Our CommBuss", link: "/aboutus" },
  { id: 4, title: "Create Package", link: "/createpackage" },
  { id: 5, title: "Contact Us", link: "/contactus" },
];
export const FooterLinks = [
  { id: 1, title: "Όροι & προϋποθέσεις", link: "/terms" },
  { id: 2, title: "Πολιτική Απορρήτου και Cookies", link: "/cookiesPolicy" },
];
export const FooterLinksEng = [
  { id: 1, title: "Terms and Conditions", link: "/terms" },
  { id: 2, title: "Cookies Policy", link: "/cookiesPolicy" },
];
